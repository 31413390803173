import chunk from 'lodash/chunk'
import { getWeb3Async } from '@/servicies/blockchain/web3'
import { balanceOf, getErc20ContractAsync } from '@/servicies/blockchain/Erc20'
import { Blockchain } from '@/constants/blockchain'

export async function getBalances ({ blockchain = Blockchain.Ethereum, chainId, address, tokens }) {
  const web3 = await getWeb3Async({ blockchain, chainId })
  const balances = {}
  const requests = []
  const callback = (token, resolve) => (err, data) => {
    if (err) {
      console.error(`Error fetching ${token.name} balance`, err)
    } else {
      balances[token.name] = data || '0'
    }
    return resolve(balances[token.name])
  }
  const bunchBatches = []
  // We divided to smaller chunks, because some rpc providers don't support large bunch requests
  for (let tokensSmallerChunk of chunk(tokens, 10)) {
    const batch = new web3.BatchRequest()
    for (let token of tokensSmallerChunk) {
      balances[token.name] = '0'
      // We need to detect in that place, is this base or erc20 currency
      let request
      if (token.isBaseCurrency) {
        request = new Promise((resolve) => batch.add(web3.eth.getBalance.request(address, callback(token, resolve))))
      } else {
        const contract = await getErc20ContractAsync({ blockchain, erc20Address: token.address, currencyName: token.name, chainId })
        request = new Promise((resolve) => batch.add(contract.methods.balanceOf(address).call.request({}, callback(token, resolve))))
      }
      requests.push(request)
    }
    bunchBatches.push(batch)
  }
  bunchBatches.forEach((batch) => batch.execute())
  await Promise.all(requests)
  return balances
}

export async function getBalanceETH ({ blockchain = Blockchain.Ethereum, chainId, address }) {
  const web3 = await getWeb3Async({ blockchain, chainId })
  const balance = await web3.eth.getBalance(address)
  return balance
}

export async function getBalanceErc20 ({ blockchain = Blockchain.Ethereum, chainId, address, currencyName, erc20Address }) {
  const balance = await balanceOf({ blockchain, chainId, owner: address, currencyName, erc20Address })
  return balance.toString()
}
